<template>
    <keep-alive>
    <loginon />
    </keep-alive>
</template>

<script>
// @ is an alias to /src
import loginon from "@/components/Loginon";

export default {
  name: "Login",
  components: {
    loginon
  }
};
</script>
